@import url(https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap);
body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}

:root {
  --mobile-limit: 960px;
}

html {
  background-color: #1B2430;
}

hr {
  background: none;
  border: none;
  border-bottom: solid #D9DBDD 1px;
}

.App {
  box-sizing: border-box;
  background-color: #F7F9FC;
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.Auth_root__2-oaG {
    width: 20%;
    margin: auto;
}

.Auth_input__3dnNF {
    margin-bottom: 15px;
}

.Auth_underButton__6gB_v {
    margin-top: 8px;
    text-align: right;
}

.Auth_link__3U9Vm {
    color: #1365ff;
    text-decoration: none;
}


@-webkit-keyframes Home_fadein__3B7Zd {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes Home_fadein__3B7Zd {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes Home_fromtop__2PMu_ {
    0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes Home_fromtop__2PMu_ {
    0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@-webkit-keyframes Home_frombottom__2lMLC {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes Home_frombottom__2lMLC {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

.Home_welcome__2Hw7n {
    -webkit-animation: Home_fadein__3B7Zd 1s, Home_fromtop__2PMu_ 1s;
            animation: Home_fadein__3B7Zd 1s, Home_fromtop__2PMu_ 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Home_divider__1CTcn {
    margin: 20px 0px;
}

.Home_welcomehidden__O4J_j {
    opacity: 0;
}

.Home_content__1UkNS {
    -webkit-transition-delay: 250ms;
            transition-delay: 250ms;
    -webkit-animation: Home_fadein__3B7Zd 1s, Home_frombottom__2lMLC 1s;
            animation: Home_fadein__3B7Zd 1s, Home_frombottom__2lMLC 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
    min-height: 75vh;
}

.Home_contenthidden__FbH4X {
    min-height: 75vh;
    opacity: 0;
}

.Home_listened__21PyO {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Home_firstleft__9HE-H {
    padding-bottom: 16px;
    padding-right: 8px;
}

.Home_secondleft__Iw_nZ {
    padding-bottom: 16px;
    padding-left: 8px;
}

@media only screen and (max-width: 960px) {
    .Home_listened__21PyO {
    }
    
    .Home_firstleft__9HE-H {
        padding-right: unset;
    }
    
    .Home_secondleft__Iw_nZ {
        padding-left: unset;
    }
}

.Home_username__2GfOP {
    color: #1365ff;
}

.History_root__3_C4o {
    position: relative;
}

.History_trackitem__THhbe {
}

.History_title__2bUXu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}



.Track_root__lGeDX {
    position: relative;
    background-color: white;
    text-align: left;
    box-shadow: 10px 10px 16px -9px rgba(0,0,0,0.09);
    overflow: hidden;
}

.Track_cover__njApB {
    position: relative;
}

.Track_cover__njApB > img {
    width: 100%;
}


.Track_bottom__1ROUv {
    display: flex;
}

.Track_play__3ou7n {
    margin-left: auto;
}

.Track_playButton__1pVrj:hover {
    -webkit-transition: all 250ms;
    transition: all 250ms;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.Track_playButton__1pVrj:active {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.Track_playButton__1pVrj {
    background: transparent !important;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 250ms;
    transition: all 250ms;
}

.Track_texts__Ws8de {
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Track_title__3vSOa {
    font-size: 0.9em;
}

.Track_author__2IowF {
    font-size: 0.7em;
    color: rgb(137, 139, 145);
}



.Line_root__3Urdc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding-right: 20px;
    height: 50px;
}

.Line_coverContainer__1ABeU {
    position: relative;
    max-height: 50px;
    max-width: 50px;
    margin-right: 15px;
}

.Line_cover__ONDg- {
    width: 100%;
}

.Line_info__39tCG {
    flex: 4 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
}

.Line_large__2zYPV {
    flex: 5 1;
}

.Line_medium__23T8X {
    flex: 4 1;
}

.Line_small__1kT-- {
    flex: 2 1;
}

.Line_tiny__2J1hc {
    flex: 1 1;
}

.Line_lastInfo__3DaxK {
    flex: 1 1;
    justify-content: flex-end;
}

.Line_playButton__3eFP_ {
    margin-right: 20px !important;
}



.BasicCard_root__1g-hX {
    text-align: left;
    padding: 20px;
}

.BasicCard_more__p81aa {
    color: green;
}

.BasicCard_less__w1OWn {
    color: red;
}

.BasicCard_middle__2xenO {
    margin: 15px 0px !important;
}




.BasicChart_paper__1YuGn {
    padding: 20px;
    height: 100%;
    position: relative;
}



.Chart_root__3qhz2 {
    height: calc(100% - 40px);
}

.Chart_buttons__3EDYJ {
    position: absolute;
    top: 0px;
    right: 0px;
}



.IntervalModifier_root__1GxZP {
}

.IntervalModifier_paper__3ciNU {
    padding: 20px;
}

.IntervalModifier_entry__-7sqi {
    margin-bottom: 10px;
}

.IntervalModifier_auto__utC1_ {
    position: absolute;
    z-index: 3;
    top: 20px;
    right: 20px;
}



.BestSong_root__3J1Hk {
    position: relative;
    height: 50px;
    padding-top: 100%;
    background-size: cover;
    overflow: hidden;
}

.BestSong_content__2YKuP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10%;
    background-color: rgba(165, 165, 165, 0.5);
}

.BestSong_image__3jbpO {
    position: relative;
}

.BestSong_image__3jbpO > img {
    width: 100%;
}

.BestSong_text__psW_S {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.BestSong_desc__3UZKD {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.BestSong_title__1jzzg {
    color: white;
    font-size: 1.5em;
}



.BestArtist_root__YPJfB {
    position: relative;
    height: 50px;
    padding-top: 100%;
    background-size: cover;
    overflow: hidden;
}

.BestArtist_content__FtTmP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10%;
    background-color: rgba(165, 165, 165, 0.5);
}

.BestArtist_image__1hk42 {
    position: relative;
}

.BestArtist_image__1hk42 > img {
    width: 100%;
}

.BestArtist_text__1SZ6R {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.BestArtist_desc__3Cu0C {
    color: white;
    font-weight: bold;
    font-size: 2em;
}

.BestArtist_title__1Dg0T {
    color: white;
    font-size: 1.5em;
}



@-webkit-keyframes ShowIfInScreen_fadein__My5rS {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ShowIfInScreen_fadein__My5rS {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ShowIfInScreen_frombottom__14ZGt {
    0% {
        -webkit-transform: translateY(250px);
                transform: translateY(250px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes ShowIfInScreen_frombottom__14ZGt {
    0% {
        -webkit-transform: translateY(250px);
                transform: translateY(250px);
    }
    100% {
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

.ShowIfInScreen_root__10o42 {
    -webkit-animation: ShowIfInScreen_frombottom__14ZGt 700ms, ShowIfInScreen_fadein__My5rS 700ms;
            animation: ShowIfInScreen_frombottom__14ZGt 700ms, ShowIfInScreen_fadein__My5rS 700ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.ShowIfInScreen_hidden__30weM {
    opacity: 0;
}



.QuickInterval_select__2qPRc {
    margin-left: 20px;
    width: 100px;
}

.QuickInterval_menuItem__p-gEN {
    padding: 0 10px;
}

.QuickInterval_every__27D_d {
    margin-left: 20px !important;
}



.HistoryScene_root__QXhe6 {
}
.Layout_root__ananT {}

.Layout_header__3A3Tc {
    height: 5vh;
    background-color: white;
}

.Layout_stat__30heI {
    flex-grow: 1;
}

.Layout_page__3Ir2i {
    display: flex;
}

.Layout_siderContainer__2pGoe {
    width: 15vw;
    position: relative;
}

.Layout_sider__hG7gA {
    padding-top: 5vh;
    background-color: #1B2430;
    position: fixed;
    z-index: 4;
    width: 15vw;
    height: 100vh;
}

.Layout_content__2ZQnQ {
    width: 85vw;
    padding: 0px 50px;
    padding-top: 5vh;
    padding-bottom: 10vh;
}

@media only screen and (max-width: 960px) {
    .Layout_content__2ZQnQ {
        width: 100vw;
        padding: 0px 10px;
        padding-top: 5vh;
    }
    .Layout_siderContainer__2pGoe {
        width: 0px;
    }
    .Layout_sider__hG7gA {
        width: 0px;
    }
}


.Sider_root__3rNRH {
    text-align: left;
    background-color: #1B2430;
    color: white;
}

.Sider_mobileDrawer__1B8fC {
    background-color: #1B2430;
    width: 70vw;
    height: 100vh;
    padding-top: 10vh;
}

.Sider_icon__2znyV {
    color: white;
}

.Sider_siderHeader__30vZQ {
    color: white !important;
}

@media only screen and (max-width: 960px) {
    .Sider_siderHeader__30vZQ {
        color: white !important;
    }
    
}


.Settings_root__1Wbch {

}

.Settings_row__37a0t {
}

.Settings_paper__BNz4s {
    width: 100%;
    padding: 20px;
}

.Settings_entry__1BcKO {
    display: flex;
    justify-content: space-between;
}

.Settings_footer__3Glez {
    padding: 20px;
    width: 100%;
}



.LogToSpotify_root__xkT6k {
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}

.LogToSpotify_entry__2M9hx {
    margin-bottom: 25px;
    display: block;
    position: relative;
}

.LogToSpotify_entry__2M9hx > img {
    width: 20%;
}

.LogToSpotify_desc__2z08m {
    width: 50%;
    margin: auto;
}



.AllStats_graph__3ZygJ {
    min-height: 350px;
}

.AllStats_title__2jMS4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}







